import React, { useContext } from 'react';
import context from '../context';
import styled from 'styled-components';
import mobileLogo from '../images/logo-light-mobil.png'

const Logo = styled.img`
 background-color: transparent;
 margin-top: 0px;
 margin-buttom: 50px;
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
  max-height: 100%;
  object-fit: fix;
  object-position: center;
`

export default ({ dark, mobile })=> {
  const state = useContext(context);
  return(
    <Logo src={dark ? state.logoDark : mobile ? mobileLogo : state.logo} alt="Logo" />
  )
}