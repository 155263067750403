import styled from 'styled-components';

export default styled.span`
  color: black;
  text-decoration: none;
  transition: 250ms ease;
  margin-left: ${props => props.first ? "0" : "1rem"};
  border: 2px solid #2a59a0;
  padding: 5px 10px;
  font-weight: bold; /* Agrega negrita al texto */

  &:visited {
    color: ${props => props.light ? "#fff" : props.dark ? "#333333" : "white"};
  }
  
  &:hover {
    color: #2a59a0;
    border: 2px solid ${props => props.theme.primaryColor}; 
  }
  
  @media(min-width: 900px) {
    border-radius: 15px;
    border: 2px solid ${props => props.theme.primaryColor};
    margin-left: ${props => props.first ? "0" : "2rem"};
    color: ${props => props.theme.primaryColor};
    font-weight: bold; /* Agrega negrita al texto */

    &:hover {
      color: #2a59a0;
      border: 2px solid ${props => props.theme.primaryColor}; 
    }
  }
`
